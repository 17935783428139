<script lang="ts">
    import Wrapper from '../utils/Wrapper.svelte';
    import { twMerge } from 'tailwind-merge';
    import type { InputType } from '../types';

    export let type: InputType = 'text';
    export let value: any = undefined;
    export let defaultClass = 'block border w-full';
    export let color: 'base' | 'green' | 'red' = 'base';
    export let floatClass = 'flex absolute inset-y-0 items-center text-gray-500 dark:text-gray-400';

    const focusedClasses = 'focus:shadow-none focus:outline-0';
    const disabledClassses = 'disabled:cursor-not-allowed disabled:bg-gray-200 disabled:border-gray-200 disabled:text-gray-500';

    const borderClasses = {
        base: 'border-gray-200 dark:border-gray-600',
        green: 'border-green-500 dark:border-green-400',
        red: 'border-red-500 dark:border-red-400',
    };

    const ringClasses = {
        base: 'focus:border-primary-500 focus:ring-primary-500 dark:focus:border-primary-500 dark:focus:ring-primary-500',
        green: 'focus:ring-green-500 focus:border-green-500 dark:focus:border-green-500 dark:focus:ring-green-500',
        red: 'focus:ring-red-500 focus:border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500',
    };

    const colorClasses = {
        base: 'bg-gray-50 text-gray-900 placeholder:text-gray-400 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400',
        green: 'bg-green-50 text-green-700 placeholder:text-gray-400 dark:text-green-400 dark:placeholder:text-gray-400 dark:bg-gray-700',
        red: 'bg-red-50 text-red-700 placeholder:text-gray-400 dark:text-red-500 dark:placeholder:text-gray-400 dark:bg-gray-700',
    };

    // you need to this to avoid 2-way binding
    const setType = (node: HTMLInputElement, _type: string) => {
        node.type = _type;
        return {
            update(_type: string) {
                node.type = _type;
            },
        };
    };

    let inputClass: string;
    $: {
        inputClass = twMerge([
            defaultClass,
            focusedClasses,
            'px-4 py-3 h-12',
            $$slots.left && 'pl-[2.625rem]',
            $$slots.right && value && 'pr-[2.625rem]',
            ringClasses[color],
            colorClasses[color],
            borderClasses[color],
            disabledClassses,
            'text-sm',
            'rounded-lg',
            $$props.class,
        ]);
    }
</script>

<Wrapper class="relative w-full" show={$$slots.left || $$slots.right}>
    {#if $$slots.left}
        <div class={twMerge(floatClass, 'pointer-events-none left-0 pl-4', $$props.classLeft)}>
            <slot name="left" />
        </div>
    {/if}
    <slot props={{ ...$$restProps, class: inputClass }}>
        <input
            {...$$restProps}
            bind:value
            on:blur
            on:change
            on:click
            on:contextmenu
            on:focus
            on:keydown
            on:keypress
            on:keyup
            on:mouseover
            on:mouseenter
            on:mouseleave
            on:paste
            on:input
            use:setType={type}
            class={inputClass}
        />
    </slot>
    {#if $$slots.right}
        <div class={twMerge(floatClass, 'right-0 pr-4', $$props.classRight)}><slot name="right" /></div>
    {/if}
</Wrapper>

<!--
  @component
  ## Feature
  [Go to Input Fields](https://flowbite-svelte.com/docs/forms/input-field)
  - Setup
  - Input fields
  - Disabled state
  - Validation
  - Input with icon
  - Icon click handler
  - Helper text
  - Number input
  - Advanced usage
  ## Props
  @prop type: InputType = 'text';
  @prop value: any = '';
  @prop defaultClass: string = 'block w-full disabled:cursor-not-allowed disabled:opacity-50';
  @prop color: 'base' | 'green' | 'red' = 'base';
  ## Event
  - on:blur
  - on:change
  - on:click
  - on:contextmenu
  - on:focus
  - on:keydown
  - on:keypress
  - on:keyup
  - on:mouseover
  - on:mouseenter
  - on:mouseleave
  - on:paste
  - on:input
  ## Example
  ```
  <form>
    <div class="grid gap-6 mb-6 md:grid-cols-2">
      <div>
        <Label for="first_name" class="mb-2">First name</Label>
        <Input type="text" id="first_name" placeholder="John" required  />
      </div>
      <div>
        <Label for="last_name" class="mb-2">Last name</Label>
        <Input type="text" id="last_name" placeholder="Doe" required />
      </div>
      <div>
        <Label for="company" class="mb-2">Company</Label>
        <Input type="text" id="company" placeholder="Flowbite" required />
      </div>
    </div>
    <Button type="submit">Submit</Button>
  </form>
  ```
-->
